@import '../app';

.header {
  .navbar-burger {
    background: transparent;
    border: none;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
