.investments-table {
  overflow-x: auto;
  display: block;
  width: fit-content;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;

  tbody {
    tr { 

      th {
        font-weight: 300;
      }
    }
  }
}
.tab-header {
  display: flex;
  margin: 0px 0px 25px 0px;
  .tab {
    width: 20%;
    border: 2px solid #000;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;;
    text-transform: uppercase;
    background: #ffafaf;
  }
  @media only screen and (max-width: 930px) {
        .tab {
          width: 100% !important ;
        }
  }
  .active{
    background:#70ad47;
    color: #fff;;
  }
  .archieve{
    background:#ff0000 !important;
    color: #fff;
  } 
}
  .show{
    display: block !important;
  }.hide{
     display: none !important;
  }
