/**
 * Google fonts
 */
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700');

/**
 * App defaults
 */
$dark-grey: #3d3d3c;
$yellow: #e5c304;
$light-grey: #c7c7c6;

/**
 * Update Bulma globals
 */
$family-sans-serif: "Nunito Sans", sans-serif;
$widescreen-enabled: false;
$fullhd-enabled: false;
$primary: $yellow;
$link: $yellow;
$dark: $dark-grey;

/**
 * Update Bulma component variables
 */

// Navbar
$navbar-height: 4.25rem;
$navbar-item-img-max-height: 2.75rem;

// Inputs
$input-shadow: none;
.input[readonly]{
  background-color: #f1f1f1;
}

// Import Bulma
@import '~bulma/bulma';
