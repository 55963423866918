$dark-grey: #3d3d3c;

.content ul.documents {
  margin-left: 0;

  li {
    display: block;
    font-size: 18px;

    a {
      align-items: center;
      color: $dark-grey;
      display: flex;

      &:hover {
        text-decoration: underline;
      }

      img {
        margin-left: 10px;
        max-height: 18px;
      }
    }
  }
}
