.loader-wrapper.is-custom {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.loader.is-custom {
  display: inline-block;
  width: 80px;
  height: 80px;
}
